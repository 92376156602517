import { createContext, useState } from 'react';
import * as subscriptionApi from '../api/subscribtion';
import { useNotification } from '../hooks/useNotification';

export const ModalContext = createContext(null);

export const ModalContextProvider = ({ children }) => {
  const [isJoinModal, setJoinModal] = useState(false);
  const [isCooperateModal, setCooperateModal] = useState(false);
  const [isMenuModal, setMenuModal] = useState(false);

  const [email, setEmail] = useState('');
  const [interest, setInterest] = useState('I’m interested in Investing');

  const { showSuccessNotification, showWarningNotification } = useNotification();

  const onEmailChange = (value) => {
    setEmail(value);
  };

  const onInterestsChange = (value) => {
    setInterest(value);
  };

  const toggleJoinModal = async () => {
    setJoinModal(!isJoinModal);
  };

  const subscribeWitelist = async () => {
    const response = await subscriptionApi.subscribeWitelist(email);

    setJoinModal(!isJoinModal);

    if (response?.status === 200) {
      showSuccessNotification();
    } else {
      showWarningNotification();
    }
  };

  const subscribeInterests = async () => {
    const response = await subscriptionApi.subscribeInterests(email, interest);

    setCooperateModal(!isCooperateModal);

    if (response?.status === 200) {
      showSuccessNotification();
    } else {
      showWarningNotification();
    }
  };

  const toggleCooperateModal = async () => {
    setCooperateModal(!isCooperateModal);
  };

  const toggleMenuModal = () => {
    setMenuModal(!isMenuModal);
  };

  return (
    <ModalContext.Provider
      value={{
        isJoinModal,
        isCooperateModal,
        isMenuModal,
        email,
        toggleJoinModal,
        toggleCooperateModal,
        toggleMenuModal,
        onEmailChange,
        onInterestsChange,
        subscribeWitelist,
        subscribeInterests,
      }}>
      {children}
    </ModalContext.Provider>
  );
};
