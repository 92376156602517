import footerLogo from '../../assets/images/footer-logo.svg';
import addSimpleIcon from '../../assets/images/add-simple.svg';
import twitterIcon from '../../assets/images/twitter.svg';
import linkedinIcon from '../../assets/images/linkedin.svg';
import instagramIcon from '../../assets/images/instagram.svg';
import canSpamIcon from '../../assets/images/can-spam.svg';
import gdprIcon from '../../assets/images/gdpr.svg';
import heart from '../../assets/images/heart.svg';
import ua from '../../assets/images/ua.svg';
import Button from '../ui/Button';
import { useScreenSize } from '../../hooks/useScreenSize';
import { useModal } from '../../hooks/useModal';
import './style.scss';

export const Footer = () => {
  const { width } = useScreenSize();
  const { toggleCooperateModal } = useModal();
  return (
    <div className="footer">
      <div className="footer-row">
        <div className="footer-column footer-column-1">
          <div className="footer-logo-wrapper">
            <img className="footer-logo" src={footerLogo} alt="" />
            <span className="footer-description-text">
              Efficient email marketing, reimagined and refined for unparalleled performance
            </span>
          </div>
          {width > 576 && (
            <div className="footer-copyright-wrapper">
              <div className="footer-made-with-love">
                <span>Made with</span>
                <img src={heart} alt="heart" />
                <span>in</span>
                <img src={ua} alt="ua" />
                <span>Ukraine</span>
              </div>
              <span className="footer-copyright">
                ©2023-2024
                <br />
                All Rights Reserved
              </span>
            </div>
          )}
        </div>
        <div className="footer-divider"></div>
        <div className="footer-column footer-column-2">
          <div className="footer-form-wrapper">
            <span className="footer-form-title">Join the waitlist</span>
            <div className="footer-form">
              <input type="text" placeholder="Your Email..." className="footer-input" />
              {width > 576 && <Button icon={addSimpleIcon}>join</Button>}
            </div>
            {width < 576 && <Button icon={addSimpleIcon}>Join the waitlist</Button>}
          </div>
          <div className="footer-links-wrapper">
            {width > 576 && <span className="footer-terms">Terms & Policies</span>}
            <span className="footer-interested" onClick={toggleCooperateModal}>
              Interested in investing?
            </span>
          </div>
        </div>
        <div className="footer-divider"></div>
        {width < 576 && (
          <div className="footer-column social-column">
            <div className="footer-social-wrapper">
              <img src={twitterIcon} alt="" />
              <img src={linkedinIcon} alt="" />
              <img src={instagramIcon} alt="" />
            </div>
          </div>
        )}
        <div className="footer-column footer-column-3">
          {width < 576 && (
            <div className="footer-copyright-wrapper">
              <span className="footer-copyright">©2023-2024 All Rights Reserved</span>
              <span className="footer-terms">Terms & Policies</span>
            </div>
          )}
          {width > 576 && (
            <div className="footer-social-wrapper">
              <a href="https://twitter.com/dinomail_io" target="_blank" rel="noreferrer">
                <img src={twitterIcon} alt="" />
              </a>
              <a href="https://www.linkedin.com/company/dinomail/" target="_blank" rel="noreferrer">
                <img src={linkedinIcon} alt="" />
              </a>
              <a href="https://www.instagram.com/dinomail.io" target="_blank" rel="noreferrer">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
          )}
          <div className="footer-gdpr-wrapper">
            <img src={canSpamIcon} alt="" />
            <img src={gdprIcon} alt="" />
          </div>
          {width < 576 && (
            <div className="footer-made-with-love-wrapper">
              <div className="footer-made-with-love">
                <span>Made with</span>
                <img src={heart} alt="heart" />
                <span>in</span>
                <img src={ua} alt="ua" />
                <span>Ukraine</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
